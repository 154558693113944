export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Sprunki Roblox",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://game.sprunki.com/sprunkiroblox.html",
    domain: "sprunkiroblox.com",
    gaId: "G-PH5P0QDWCG",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
